import axios from "axios";
import PARAMS from "utils/PARAMS";
import { showSnack } from "utils/helperFunctions";

// Instancia para api
var instance = axios.create({
  // baseURL: 'http://localhost:8000/api/',
  baseURL: 'https://api.paneldecontrol.dobaena.com/api/',
  //timeout: 120000
});

instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
      if (localStorage.getItem("apiToken")) {
        showSnack('warning', 'Sesión caducada');
        instance.get('logout');
        localStorage.removeItem('apiToken');
        window.location.replace(PARAMS.baseUrl);
      }else{
        showSnack('warning', 'Usuario no autorizado');
      }
    } else {
      return Promise.reject(error);
    }
});

// Instancia para api archivos
var instance_archivos = axios.create({
  // baseURL: 'http://localhost:8000/api/',
  baseURL: 'https://api.paneldecontrol.dobaena.com/api/',
  timeout: 120000
});

instance_archivos.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance_archivos.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
      if (localStorage.getItem("apiToken")) {
        showSnack('warning', 'Sesión caducada');
        instance_archivos.get('logout');
        localStorage.removeItem('apiToken');
        window.location.replace(PARAMS.baseUrl);
      }else{
        showSnack('warning', 'Usuario no autorizado');
      }
    } else {
      return Promise.reject(error);
    }
});

export {instance, instance_archivos};
