import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {instance as API, instance_archivos as API_ARCHIVOS} from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import Moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";
import Dropzone from "components/DropZone/DropZone";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import Select2 from 'react-select';

export default function Archivos() {
  const [archivos, setArchivos] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "oleicultor", date: false, disablePadding: true, label: 'Oleicultor'},
    {id: "fecha", date: true, disablePadding: true, label: 'Fecha de Creación'},
    {id: "acciones", date: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(oleicultor, fecha, acciones) {
    return { oleicultor, fecha, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [archivo_id, setArchivoId] = React.useState(null);
  const [archivo_isImagen, setArchivoIsImagen] = React.useState(false);
  const [archivo_isTexto, setArchivoIsTexto] = React.useState(false);
  const [archivo_imagen, setArchivoImagen] = React.useState([]);
  const [archivo_imagen_guardada, setArchivoImagenGuardada] = React.useState([]);
  const [archivo_descripcion, setArchivoDescripcion] = React.useState(EditorState.createEmpty());
  const [archivo_titulo, setArchivoTitulo] = React.useState(null);

  const [archivo_oleicultor, setArchivoOleicultor] = React.useState('');
  const [listOleicultorOptions, setListOleicultorOptions] = React.useState([]);
  const handleArchivoOleicultor = selectedOption => {
    setArchivoOleicultor(selectedOption);
  };

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setArchivoId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const handleIsTexto= () => {
    setArchivoIsTexto(!archivo_isTexto);
    setArchivoImagen([]);
    setArchivoDescripcion(EditorState.createEmpty());
  };

  const handleImage = (img) => {
    var arrayImagenes = [];
    img.forEach((item, i) => {
      arrayImagenes[0] = item['file'];
    });

    setArchivoImagen(arrayImagenes);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
  };
  const useStyles = makeStyles(styles);
  Moment.locale('es');

  useEffect(() => {
    getArchivos();
  }, []);

  async function getArchivos() {
    setIsLoad(false);
    await API.post("archivos/index", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data.listaOleicultores) {
        setListOleicultorOptions(res.data.listaOleicultores);
      }
      if (res.data.archivos.length > 0) {
        const arrayArchivos = [];
        res.data.archivos.forEach(archivo => {
          let oleicultor = archivo['oleicultor'];
          let aux = createData(
            archivo["oleicultor"]["nombre"],
            Moment(archivo["created_at"]).format('DD-MM-YYYY HH:mm:ss'),
            (<div className={classes.root}>
              <Actions
                edit={true}
                onEdit={() => loadEdit(
                  archivo['id'],
                  archivo['titulo'],
                  archivo["texto"],
                  ((oleicultor) ? {'value': oleicultor['id'], 'label': oleicultor['nombre']} : []),
                  archivo["imagen"]
                )}
                del={true}
                onDelete={() => handleClickOpenConfirm(archivo['id'])}
              />
            </div>)
          );

          arrayArchivos.push(aux);
        });
        setArchivos(arrayArchivos);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error en la carga de archivos')
    });
  }

  function formArchivos(){
    return(<>
      {archivo_id == null && <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }} >
        <FormControlLabel
          control={
            <Checkbox
              checked={archivo_isTexto}
              onChange={() => handleIsTexto()}
              value="checkedB"
              color="primary"
              classes={{root: classes.radioCustom, checked: classes.checked}}
            />
          }
          label="Editor de Textos"
        />
      </GridItem>}
      {archivo_isTexto ?
        <>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
            <TextField
              label="Nombre"
              defaultValue={archivo_titulo}
              onChange={event => {
                const { value } = event.target;
                setArchivoTitulo(value);
              }}
              error={(error && archivo_titulo == null) ? true : false}
              fullWidth={true}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
            <Editor
              editorState={archivo_descripcion}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(value) => setArchivoDescripcion(value)}
              localization={{
                locale: 'es',
              }}
            />
          </GridItem>
        </>
      :
        <>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}>
            <Dropzone onLoadImage={(val) => handleImage(val)} initialFiles={archivo_imagen} multiple={false} maxFiles={1} insideMessage="Arrastre o suelte el archivo aquí, o haga clic para seleccionarlo"/>
          </GridItem>
          <GridItem xs={2} sm={2} md={2} style={{ marginTop: "20px", display: 'flex', flexDirection: 'column' }} >
            {(archivo_imagen_guardada && archivo_imagen_guardada['url']) && <>
              <p style={{fontSize: 20, fontWeight: 'bold', color: '#2484FF'}}>Archivo Guardado</p>
              {(archivo_imagen_guardada && archivo_imagen_guardada['is_image'] == 1) ? <>
                <img src={archivo_imagen_guardada['url']} width="100%"/>
              </>:<>
                <a download href={archivo_imagen_guardada['url']} target="_blank">Descargar</a>
              </>}
            </>}
          </GridItem>
        </>
      }

      <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }} >
        <Select2
          value={archivo_oleicultor}
          onChange={handleArchivoOleicultor}
          options={listOleicultorOptions}
          className={(error && archivo_oleicultor.value == null) ? classes.error : ""}
          placeholder ="Selecciona oleicultor"
          menuPosition={'fixed'}
        />
      </GridItem>

    </>);
  }

  async function createArchivo(){

    if (!isProcessing) {
      setError(false);
      if ((archivo_oleicultor && archivo_oleicultor['value']) && (archivo_imagen[0] || (archivo_descripcion && archivo_titulo))) {
        setIsProcessing(true);
        if (archivo_imagen[0]) {
          let data = new FormData();
          data.append('file', archivo_imagen[0]);
          let settings = { headers: { 'content-type': 'multipart/form-data' } }
          await API.post("documentos/upload", data, settings)
          .then(async(res) => {
            if (res.data.id) {
              store(res.data.id);
            }
          });
        }else{
          store(null);
        }
      }else{
        showSnack('warning', 'Introduce oleicultor y archivo(adjunto o por editor de texto)');
        setError(true);
      }
    }
  }

  async function store(doc_id){
    await API.post("archivos/store", {
      titulo: archivo_titulo,
      texto: draftToHtml(convertToRaw(archivo_descripcion.getCurrentContent())),
      oleicultor_id: archivo_oleicultor['value'],
      documento_id: doc_id
    })
    .then(res => {
      if (res.data) {
        handleClose();
        getArchivos();
        showSnack('success', 'Archivo creada correctamente');
      }
      setIsProcessing(false);
    })
    .catch(err => {
      if (err.response.data.error) {
        showSnack('warning', err.response.data.error);
      }else{
        showSnack('warning', 'Se ha producido un error');
      }
      setIsProcessing(false);
    });
  }

  async function update(doc_id){
    await API.post(`archivos/update/${archivo_id}`, {
      titulo: archivo_titulo,
      texto: draftToHtml(convertToRaw(archivo_descripcion.getCurrentContent())),
      oleicultor_id: archivo_oleicultor['value'],
      documento_id: doc_id
    })
    .then(res => {
      if (res.data) {
        handleClose();
        getArchivos();
        showSnack('success', 'Archivo creada correctamente');
      }
      setIsProcessing(false);
    })
    .catch(err => {
      if (err.response.data.error) {
        showSnack('warning', err.response.data.error);
      }else{
        showSnack('warning', 'Se ha producido un error');
      }
      setIsProcessing(false);
    });
  }

  function clearForm(){
    setArchivoId(null);
    setArchivoTitulo(null);
    setArchivoDescripcion(EditorState.createEmpty());
    setArchivoOleicultor([]);
    setArchivoImagenGuardada([]);
  }

  function loadEdit(id, titulo, descripcion, oleicultor, imagen){
    setArchivoId(id);
    const contentBlock = htmlToDraft(descripcion);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setArchivoDescripcion(editorState);
    }
    setArchivoTitulo(titulo);
    setArchivoOleicultor(oleicultor);
    setArchivoImagenGuardada(imagen)
    if (imagen) {
      setArchivoIsTexto(false);
    }else{
      setArchivoIsTexto(true);
    }
    handleClickOpenEdit();
  }

  async function editArchivo(){

    if (!isProcessing) {
      setError(false);
      if ((archivo_oleicultor && archivo_oleicultor['value']) && (archivo_imagen[0] || (archivo_descripcion && archivo_titulo))) {
        setIsProcessing(true);
        if (archivo_imagen[0]) {
          let data = new FormData();
          data.append('file', archivo_imagen[0]);
          let settings = { headers: { 'content-type': 'multipart/form-data' } }
          await API.post("documentos/upload", data, settings)
          .then(async(res) => {
            if (res.data.id) {
              update(res.data.id);
            }
          });
        }else{
          update(null);
        }
      }else{
        showSnack('warning', 'Introduce oleicultor y archivo(adjunto o por editor de texto)');
        setError(true);
      }
    }
  }

  async function deleteArchivo(){
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`archivos/destroy/${archivo_id}`)
      .then(res => {
        if (res.data) {
          handleClose();
          getArchivos();
          showSnack('success', 'Archivo eliminado correctamente');
          setIsProcessing(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsProcessing(false);
        showSnack('warning', 'Se ha producido un error, eliminando la archivo')
      });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Archivos</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de archivos
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
              <GridItem xs={3} sm={3} md={3}>
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => handleClickOpen()}>
                  <AddIcon />
                  Crear archivo
                </Fab>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} >
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={event => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getArchivos();
                    }
                  }}
                />
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => getArchivos()}>
                  <SearchIcon />
                </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={archivos}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={5000} height={100} width={100} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formArchivos()}
          onConfirm={() => createArchivo()}
          title="Crear archivo"
          width="lg"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formArchivos()}
          onConfirm={() => editArchivo()}
          title="Editar archivo"
          width="lg"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteArchivo()}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar la archivo?"  />
      </div>
    </GridContainer>
  );
}
