import React, { useState, useEffect, useRef } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import { crearNoticia,editarNoticiaSeleccionada } from "utils/API_V2";

import { instance as API, instance_archivos as API_ARCHIVOS } from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from "utils/helperFunctions";
import Moment from "moment";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Actions from "components/Actions/Actions";
import Dropzone from "components/DropZone/DropZone";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import axios from "axios";
// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SearchIcon from "@material-ui/icons/Search";
import Select2 from "react-select";
import Axios from "axios";

export default function Noticias() {
  const [noticias, setNoticias] = useState([]);
  const [tableHead, setTableHead] = useState([
    { id: "nombre", date: false, disablePadding: true, label: "Nombre" },
    {
      id: "fecha",
      date: true,
      disablePadding: true,
      label: "Fecha de Creación",
    },
    { id: "acciones", date: false, disablePadding: true, label: "Acciones" },
  ]);

  function createData(nombre, fecha, acciones) {
    return { nombre, fecha, acciones };
  }

  const myRef = useRef(null);
  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [noticia_id, setNoticiaId] = React.useState("");
  const [noticia_nombre, setNoticiaNombre] = React.useState("");
  const [noticia_adjunto_ids, setNoticiaAdjuntoIds] = React.useState([]);
  const [noticia_adjunto, setNoticiaAdjunto] = React.useState([]);
  const [noticia_adjunto_guardado, setNoticiaAdjuntoGuardado] = React.useState(
    []
  );
  const [noticia_imagen, setNoticiaImagen] = React.useState([]);
  const [noticia_imagen_guardada, setNoticiaImagenGuardada] = React.useState(
    []
  );
  const [noticia_descripcion, setNoticiaDescripcion] = React.useState(
    EditorState.createEmpty()
  );
  const [noticia_isGeneral, setNoticiaIsGeneral] = React.useState("");

  const [noticia_categoria, setNoticiaCategoria] = React.useState("");
  const [listCategoriaOptions, setListCategoriaOptions] = React.useState([]);
  const handleNoticiaCategoria = (selectedOption) => {
    setNoticiaCategoria(selectedOption);
  };

  const [noticia_oleicultor, setNoticiaOleicultor] = React.useState("");
  const [listOleicultorOptions, setListOleicultorOptions] = React.useState([]);
  const handleNoticiaOleicultor = (selectedOption) => {
    setNoticiaOleicultor(selectedOption);
  };
  const [noticia_entidad, setNoticiaEntidad] = React.useState("");
  const [listEntidadesOptions, setListEntidadesOptions] = React.useState([]);
  const handleNoticiaEntidad = (selectedOption) => {
    var aux = noticia_oleicultor;
    var add = true;
    if (!aux) {
      aux = [];
    }
    listOleicultorOptions.forEach((oleicultor, i) => {
      add = true;
      aux.forEach((selected, i) => {
        if (selected.value == oleicultor.value) {
          add = false;
        }
      });
      if (oleicultor.entidad == selectedOption.value && add) {
        aux.push(oleicultor);
      }
    });
    setNoticiaOleicultor(aux);
    myRef.current.onMenuOpen();
    setTimeout(function () {
      myRef.current.onMenuClose();
    }, 10);
  };

  const handleIsGeneral = () => {
    setNoticiaIsGeneral(!noticia_isGeneral);
    setNoticiaOleicultor([]);
    setNoticiaEntidad([]);
  };

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta);
  };

  useEffect(() => {
    let arrayArchivosAdjuntos = [];

    if (noticia_adjunto.File != undefined) {
      let ficheros = Object.keys(noticia_adjunto.File);

      for (let index = 0; index < ficheros.length; index++) {
        arrayArchivosAdjuntos.push(noticia_adjunto.File[index].file);
      }
    }

    // setNoticiaAdjunto(arrayArchivosAdjuntos);
    console.log(arrayArchivosAdjuntos);
  }, [noticia_adjunto]);

  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setNoticiaId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const handleImage = (img) => {
    // var arrayImagenes = [];
    // img.forEach((item, i) => {
    //   var previewUrl = item["meta"]["previewUrl"];
    //   var xhr = new XMLHttpRequest();
    //   xhr.open("GET", previewUrl, true);
    //   xhr.responseType = "blob";
    //   xhr.onload = function (e) {
    //     if (this.status == 200) {
    //       var myBlob = this.response;
    //       var reader = new FileReader();
    //       reader.readAsDataURL(myBlob);
    //       reader.onloadend = function () {
    //         var base64data = reader.result;
    //         arrayImagenes[0] = base64data;
    //       };
    //     }
    //   };
    //   xhr.send();
    // });
    // console.log(arrayImagenes[0]);
    // setNoticiaImagen(arrayImagenes);
    setNoticiaImagen(img);
  };

  const handleAuxAdjunto = (adjunto) => {
    console.log({ adjunto });
  };

  const handleAdjunto = (img) => {
    console.log({ img });
    var arrayImagenes = [];
    img.forEach((item, i) => {
      arrayImagenes[i] = item["file"];
    });
    setNoticiaAdjunto(arrayImagenes);
    console.log({ noticia_adjunto });
  };

  const handleArchivosAdjuntos = (archivo) => {
    console.log({ archivo });
    let arrayArchivos = [];

    archivo.forEach((item, i) => {
      arrayArchivos[i] = item["file"];
    });

    console.log(arrayArchivos);

    setNoticiaAdjunto(arrayArchivos);

    console.log({ noticia_adjunto });
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
    root: {
      width: "100%",
    },
  };
  const useStyles = makeStyles(styles);
  Moment.locale("es");

  useEffect(() => {
    getNoticias();
  }, []);

  async function getNoticias() {
    setIsLoad(false);
    await API.post("noticias/index", {
      findBy: toSeach,
    })
      .then((res) => {
        if (res.data.listaOleicultores) {
          setListOleicultorOptions(res.data.listaOleicultores);
        }
        if (res.data.listaEntidades) {
          setListEntidadesOptions(res.data.listaEntidades);
        }
        if (res.data.listaCategorias) {
          setListCategoriaOptions(res.data.listaCategorias);
        }
        if (res.data.noticias.length > 0) {
          const arrayNoticias = [];
          res.data.noticias.forEach((noticia) => {
            var oleicultoresArray = [];
            if (noticia["oleicultores"]) {
              noticia["oleicultores"].forEach((oleicultor) => {
                oleicultoresArray.push({
                  value: oleicultor["id"],
                  label: oleicultor["nombre"] + " (" + oleicultor["dni"] + ")",
                });
              });
            }
            let categoria = noticia["categoria"];
            let aux = createData(
              noticia["nombre"],
              Moment(noticia["created_at"]).format("DD-MM-YYYY HH:mm:ss"),
              <div className={classes.root}>
                <Actions
                  edit={true}
                  onEdit={() =>
                    loadEdit(
                      noticia["id"],
                      noticia["nombre"],
                      noticia["descripcion"],
                      noticia["is_general"] == 1 ? true : false,
                      oleicultoresArray,
                      noticia["imagen"],
                      noticia["adjuntos"],
                      categoria
                        ? { value: categoria["id"], label: categoria["nombre"] }
                        : []
                    )
                  }
                  del={true}
                  onDelete={() => handleClickOpenConfirm(noticia["id"])}
                />
              </div>
            );

            arrayNoticias.push(aux);
          });
          setNoticias(arrayNoticias);
          setIsLoad(true);
        }
      })
      .catch((err) => {
        console.log(err);
        showSnack(
          "warning",
          "Se ha producido un error en la carga de noticias"
        );
      });
  }

  function formNoticias() {
    return (
      <>
        <GridItem xs={8} sm={8} md={8} style={{ marginTop: "10px" }}>
          <TextField
            label="Nombre"
            defaultValue={noticia_nombre}
            onChange={(event) => {
              const { value } = event.target;
              setNoticiaNombre(value);
            }}
            error={error && noticia_nombre == "" ? true : false}
            fullWidth={true}
          />
        </GridItem>
        <GridItem
          xs={4}
          sm={4}
          md={4}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={noticia_isGeneral}
                onChange={() => handleIsGeneral()}
                value="checkedB"
                color="primary"
                classes={{
                  root: classes.radioCustom,
                  checked: classes.checked,
                }}
              />
            }
            label="General"
          />
        </GridItem>

        {(!noticia_imagen_guardada || !noticia_imagen_guardada["url"]) && (
          <>
            <GridItem xs={1} sm={1} md={1} style={{ marginTop: "10px" }} />
          </>
        )}
        <GridItem
          xs={10}
          sm={10}
          md={10}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Dropzone
            // onLoadImage={(val) => handleImage(val)}
            onLoadImage={(File) => {
              setNoticiaImagen(File);
            }}
            initialFiles={noticia_imagen}
            multiple={false}
            maxFiles={1}
            insideMessage="Arrastre o suelte la imagen aquí, o haga clic para seleccionarla"
          />
        </GridItem>
        {noticia_imagen_guardada && noticia_imagen_guardada["url"] ? (
          <GridItem
            xs={2}
            sm={2}
            md={2}
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ fontSize: 20, fontWeight: "bold", color: "#2484FF" }}>
              Imagen Guardada
            </p>
            <img src={noticia_imagen_guardada["url"]} width="100%" />
          </GridItem>
        ) : (
          <GridItem xs={1} sm={1} md={1} style={{ marginTop: "10px" }} />
        )}

        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
          <Editor
            editorState={noticia_descripcion}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(value) => setNoticiaDescripcion(value)}
            localization={{
              locale: "es",
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
          <Select2
            value={noticia_categoria}
            onChange={handleNoticiaCategoria}
            options={listCategoriaOptions}
            className={
              error && noticia_categoria.value == null ? classes.error : ""
            }
            placeholder="Añadir categoria"
            menuPosition={"fixed"}
          />
        </GridItem>
        {!noticia_isGeneral && (
          <>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
              <Select2
                value={noticia_entidad}
                onChange={handleNoticiaEntidad}
                options={listEntidadesOptions}
                className={
                  error && noticia_entidad.value == null ? classes.error : ""
                }
                placeholder="Añadir entidad"
                menuPosition={"fixed"}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
              <Select2
                isMulti
                value={noticia_oleicultor}
                onChange={handleNoticiaOleicultor}
                options={listOleicultorOptions}
                className={
                  error && noticia_oleicultor.value == null ? classes.error : ""
                }
                placeholder="Selecciona oleicultor"
                menuPosition={"fixed"}
                ref={myRef}
              />
            </GridItem>
          </>
        )}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{ display: "flex", flexDirection: "row" }}
        >
          {!noticia_adjunto_guardado && (
            <>
              <GridItem xs={1} sm={1} md={1} style={{ marginTop: "10px" }} />
            </>
          )}
          <GridItem
            xs={10}
            sm={10}
            md={10}
            style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}
          >
            <Dropzone
              // onLoadImage={(val) => handleArchivosAdjuntos(val)}
              onLoadImage={(file) => {
                setNoticiaAdjunto({
                  ...noticia_adjunto,
                  file,
                });
              }}
              onChangeStatus={handleChangeStatus}
              initialFiles={noticia_adjunto}
              insideMessage="Adjuntar archivos"
            />
          </GridItem>
          {noticia_adjunto_guardado ? (
            <GridItem
              xs={2}
              sm={2}
              md={2}
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p style={{ fontSize: 20, fontWeight: "bold", color: "#2484FF" }}>
                Adjuntos actuales
              </p>
              {noticia_adjunto_guardado.map(function (object, i) {
                return (
                  <ButtonGroup
                    variant="contained"
                    aria-label="contained primary button group"
                    style={{ marginBottom: 5 }}
                  >
                    <Button>
                      <a
                        title={object.name}
                        href={
                          PARAMS.apiUrl +
                          "documentos/download/" +
                          object.id +
                          "?token=" +
                          localStorage.getItem("apiToken")
                        }
                        target="_blank"
                      >
                        Descargar
                      </a>
                    </Button>
                    <Button
                      title="Eliminar"
                      onClick={() => borrarAdjunto(object.id)}
                      style={{
                        backgroundColor: PARAMS.firstColor,
                        color: "#fff",
                      }}
                    >
                      X
                    </Button>
                  </ButtonGroup>
                );
              })}
            </GridItem>
          ) : (
            <GridItem xs={1} sm={1} md={1} style={{ marginTop: "10px" }} />
          )}
        </GridItem>
      </>
    );
  }

  async function upload(data, settings) {
    await API_ARCHIVOS.post("documentos/upload", data, settings).then(
      async (res) => {
        if (res.data.id) {
          let aux = noticia_adjunto_ids;
          console.log(aux);
          aux.push(res.data.id);
          setNoticiaAdjuntoIds(aux);
        }
      }
    );
  }

  async function guardarNoticia() {
    await crearNoticia({
      nombre: noticia_nombre,
      descripcion: draftToHtml(
        convertToRaw(noticia_descripcion.getCurrentContent())
      ),
      oleicultores: noticia_oleicultor,
      is_general: noticia_isGeneral,
      imagen: noticia_imagen,
      documentos_adjuntos: noticia_adjunto.file ? noticia_adjunto.file:[],

      categoria_id: noticia_categoria["value"],
    })
      .then((res) => {
        console.log({ res });
        if (res.data) {
          handleClose();
          getNoticias();
          showSnack("success", "Noticia creada correctamente");
        }
        setIsProcessing(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          showSnack("warning", err.response.data.error);
        } else {
          showSnack("warning", "Se ha producido un error");
        }
        setIsProcessing(false);
      });
  }



  async function actualizarNoticia() {
    
    const res = await editarNoticiaSeleccionada({
      nombre: noticia_nombre,
      descripcion: draftToHtml(
        convertToRaw(noticia_descripcion.getCurrentContent())
      ),
      oleicultores: noticia_oleicultor,
      is_general: noticia_isGeneral,
      imagen: noticia_imagen,
      documentos_adjuntos: noticia_adjunto.file ? noticia_adjunto.file:[],

    },noticia_id).then((res) => {
      if(res.data){
        handleClose();
        getNoticias();
        showSnack("success", "Noticia actualizada correctamente");
      }
      setIsProcessing(false);
    }).catch((err) => {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.error
      ) {
        showSnack("warning", err.response.data.error);
      } else {
        showSnack("warning", "Se ha producido un error");
      }
      setIsProcessing(false);
    })

    
    // await editarNoticiaSeleccionada({
    //   nombre: noticia_nombre,
    //   descripcion: draftToHtml(
    //     convertToRaw(noticia_descripcion.getCurrentContent())
    //   ),
    //   oleicultores: noticia_oleicultor,
    //   is_general: noticia_isGeneral,
    //   imagen: noticia_imagen,
    //   documentos_adjuntos: noticia_adjunto.file,

    //   categoria_id: noticia_categoria["value"],
    // },noticia_id)
    //   .then((res) => {
    //     console.log({ res });
    //     if (res.data) {
    //       handleClose();
    //       getNoticias();
    //       showSnack("success", "Noticia creada correctamente");
    //     }
    //     setIsProcessing(false);
    //   })
    //   .catch((err) => {
    //     if (
    //       err &&
    //       err.response &&
    //       err.response.data &&
    //       err.response.data.error
    //     ) {
    //       showSnack("warning", err.response.data.error);
    //     } else {
    //       showSnack("warning", "Se ha producido un error");
    //     }
    //     setIsProcessing(false);
    //   });

  }



  function subirNoticia() {
    var validate_fields = new Map([
      ["nombre", noticia_nombre],
      ["contenido", noticia_descripcion],
      ["categoria", noticia_categoria],
      ["imagen", noticia_imagen],
    ]);

    var validate = validateFields(validate_fields);

    if (
      (validate.status && noticia_isGeneral) ||
      noticia_oleicultor.length > 0
    ) {
      guardarNoticia();
    } else {
      if (validate.message) {
        showSnack("warning", validate.message);
      } else {
        showSnack(
          "warning",
          "Debes seleccionar si es un aviso general o si va vinculada a unos oleicultores en concreto"
        );
      }
    }
  }



  function editarNoticia() {
    var validate_fields = new Map([
      ["nombre", noticia_nombre],
      ["contenido", noticia_descripcion],
      ["categoria", noticia_categoria],
    ]);

    var validate = validateFields(validate_fields);

    if (
      (validate.status && noticia_isGeneral) ||
      noticia_oleicultor.length > 0
    ) {
      actualizarNoticia();
    } else {
      if (validate.message) {
        showSnack("warning", validate.message);
      } else {
        showSnack(
          "warning",
          "Debes seleccionar si es un aviso general o si va vinculada a unos oleicultores en concreto"
        );
      }
    }
  }








  // function createArchivo(isUpdate) {
  //   if (!isProcessing) {
  //     var validate_fields = new Map([
  //       ["nombre", noticia_nombre],
  //       ["contenido", noticia_descripcion],
  //       ["categoria", noticia_categoria],
  //       ["imagen", noticia_imagen],
  //     ]);
  //     if (isUpdate) {
  //       var validate_fields = new Map([
  //         ["nombre", noticia_nombre],
  //         ["contenido", noticia_descripcion],
  //         ["categoria", noticia_categoria["value"]],
  //       ]);
  //     }

  //     var validate = validateFields(validate_fields);
  //     setError(false);
  //     if (
  //       validate.status &&
  //       (noticia_isGeneral || noticia_oleicultor.length > 0)
  //     ) {
  //       setIsProcessing(true);
  //       if (noticia_adjunto) {
  //         noticia_adjunto.forEach((item, i) => {
  //           let data = new FormData();
  //           data.append("file", item);
  //           let settings = {
  //             headers: { "content-type": "multipart/form-data" },
  //           };
  //           // upload(data, settings);
  //         });
  //         setTimeout(function () {
  //           if (isUpdate) {
  //             editNoticia();
  //           } else {
  //             createNoticia();
  //           }
  //         }, 1000);
  //       } else {
  //         if (isUpdate) {
  //           editNoticia();
  //         } else {
  //           createNoticia();
  //         }
  //       }
  //     } else {
  //       if (validate.message) {
  //         showSnack("warning", validate.message);
  //       } else {
  //         showSnack(
  //           "warning",
  //           "Debes seleccionar si es un aviso general o si va vinculada a unos oleicultores en concreto"
  //         );
  //       }
  //       setError(true);
  //     }
  //   }
  // }

  // async function createNoticia() {
  //   if (!isProcessing) {
  //     setError(false);
  //     setIsProcessing(true);
  //     await API.post("noticias/store", {
  //       nombre: noticia_nombre,
  //       descripcion: draftToHtml(
  //         convertToRaw(noticia_descripcion.getCurrentContent())
  //       ),
  //       oleicultores: noticia_oleicultor,
  //       is_general: noticia_isGeneral,
  //       imagen: noticia_imagen,
  //       array_noticia_adjunto: noticia_adjunto.Files,
  //       categoria_id: noticia_categoria["value"],
  //     })
  //       .then((res) => {
  //         console.log({ res });
  //         if (res.data) {
  //           handleClose();
  //           getNoticias();
  //           showSnack("success", "Noticia creada correctamente");
  //         }
  //         setIsProcessing(false);
  //       })
  //       .catch((err) => {
  //         if (
  //           err &&
  //           err.response &&
  //           err.response.data &&
  //           err.response.data.error
  //         ) {
  //           showSnack("warning", err.response.data.error);
  //         } else {
  //           showSnack("warning", "Se ha producido un error");
  //         }
  //         setIsProcessing(false);
  //       });
  //   }
  // }

  async function borrarAdjunto(id) {
    setIsLoad(false);
    await API.post(`documentos/destroy/${id}`)
      .then((res) => {
        var aux = noticia_adjunto_guardado;
        setNoticiaAdjuntoGuardado([]);

        let index = -1;
        aux.forEach((item, i) => {
          if (item.id == id) {
            index = i;
          }
        });

        if (index > -1) {
          aux.splice(index, 1);
        }
        setNoticiaAdjuntoGuardado(aux);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          showSnack("warning", err.response.data.error);
        } else {
          showSnack("warning", "Se ha producido un error");
        }
        setIsProcessing(false);
      });
  }

  function clearForm() {
    setNoticiaId("");
    setNoticiaNombre("");
    setNoticiaDescripcion(EditorState.createEmpty());
    setNoticiaIsGeneral(false);
    setNoticiaOleicultor([]);
    setNoticiaEntidad([]);
    setNoticiaImagenGuardada([]);
    setNoticiaAdjuntoIds([]);
    setNoticiaAdjunto([]);
    setNoticiaAdjuntoGuardado([]);
  }

  function loadEdit(
    id,
    nombre,
    descripcion,
    is_general,
    oleicultores,
    imagen,
    adjuntos,
    categoria
  ) {
    setNoticiaId(id);
    setNoticiaNombre(nombre);
    const contentBlock = htmlToDraft(descripcion);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setNoticiaDescripcion(editorState);
    }
    setNoticiaIsGeneral(is_general);
    setNoticiaOleicultor(oleicultores);
    setNoticiaImagenGuardada(imagen);
    setNoticiaAdjuntoGuardado(adjuntos);
    setNoticiaCategoria(categoria);
    handleClickOpenEdit();
  }

  // async function editNoticia() {
  //   if (!isProcessing) {
  //     var validate_fields = new Map([
  //       ["nombre", noticia_nombre],
  //       ["contenido", noticia_descripcion],
  //       ["categoria", noticia_categoria["value"]],
  //     ]);
  //     var validate = validateFields(validate_fields);
  //     setError(false);
  //     if (validate.status) {
  //       setIsProcessing(true);
  //       await API.post(`noticias/update/${noticia_id}`, {
  //         nombre: noticia_nombre,
  //         descripcion: draftToHtml(
  //           convertToRaw(noticia_descripcion.getCurrentContent())
  //         ),
  //         oleicultores: noticia_oleicultor,
  //         is_general: noticia_isGeneral,
  //         imagen: noticia_imagen,
  //         noticia_adjunto_ids: noticia_adjunto_ids,
  //         categoria_id: noticia_categoria["value"],
  //       })
  //         .then((res) => {
  //           if (res.data) {
  //             handleClose();
  //             setNoticiaAdjuntoIds([]);
  //             getNoticias();
  //             showSnack("success", "Noticia editado correctamente");
  //           }
  //           setIsProcessing(false);
  //         })
  //         .catch((err) => {
  //           if (
  //             err &&
  //             err.response &&
  //             err.response.data &&
  //             err.response.data.error
  //           ) {
  //             showSnack("warning", err.response.data.error);
  //           } else {
  //             showSnack("warning", "Se ha producido un error");
  //           }
  //           setIsProcessing(false);
  //         });
  //     } else {
  //       showSnack("warning", validate.message);
  //       setError(true);
  //     }
  //   }
  // }

  async function deleteNoticia() {
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`noticias/destroy/${noticia_id}`)
        .then((res) => {
          if (res.data) {
            handleClose();
            getNoticias();
            showSnack("success", "Noticia eliminado correctamente");
            setIsProcessing(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsProcessing(false);
          showSnack(
            "warning",
            "Se ha producido un error, eliminando la noticia"
          );
        });
    }
  }

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Noticias</h4>
            <p className={classes.cardCategoryWhite}>Gestión de noticias</p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row" alignItems="flex-end">
              <GridItem xs={3} sm={3} md={3}>
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => handleClickOpen()}
                >
                  <AddIcon />
                  Crear noticia
                </Fab>
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={(event) => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getNoticias();
                    }
                  }}
                />
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => getNoticias()}
                >
                  <SearchIcon />
                </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {isLoad ? (
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={noticias}
                  />
                </>
              ) : (
                <>
                  <GridContainer
                    style={{ width: "100%", height: "300px" }}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Loader
                      type={PARAMS.loaderType}
                      color={PARAMS.firstColor}
                      timeout={5000}
                      height={100}
                      width={100}
                    />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formNoticias()}
          onConfirm={() => subirNoticia()}
          title="Crear noticia"
          width="lg"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formNoticias()}
          onConfirm={() => editarNoticia()}
          title="Editar noticia"
          width="lg"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteNoticia()}
          confirmText="Confirmar"
          confirmIcon={
            <CheckCircleOutlineIcon
              style={{ marginRight: "10px", color: "#fff" }}
            />
          }
          title="¿Seguro que deseas borrar la noticia?"
        />
      </div>
    </GridContainer>
  );
}
