import axios from "axios";
import PARAMS from "utils/PARAMS";

// Instancia para api de partes
var instance = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000000,
});
let isLogged = localStorage.getItem("apiToken") ? true : false;

instance.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem("apiToken")) {
            // toast("Sesión caducada", { type: "error" });
            axios.get("logout");
            localStorage.removeItem("apiToken");
            localStorage.removeItem("userType");
            localStorage.removeItem("userEmail");
            window.location.replace(PARAMS.baseUrl);
          } else {
            // toast("Usuario no autorizado", { type: "error" });
          }
          break;
        case 404:
          //   toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;

        default:
      }
    } else {
      //   toast("No se ha podido establecer conexión", { type: "error" });
    }

    return Promise.reject(error);
  }
);

// Instancia para ws formData
var instance_fd = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 5000,
});

instance_fd.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("apiToken");
instance_fd.defaults.headers.common["Content-Type"] = "multipart/form-data";
instance_fd.interceptors.response.use(
  async function (response) {
    return response;
  },

  async function (error) {
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem("apiToken")) {
            // toast("Sesión caducada", { type: "error" });
            axios.get("logout");
            localStorage.removeItem("apiToken");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("userType");
            window.location.replace(PARAMS.baseUrl);
          } else {
            // toast("Usuario no autorizado", { type: "error" });
          }
          break;
        case 404:
          //   toast("Url no disponible", { type: "error" });
          break;
        case 500:
          break;
        default:
      }
    }
  }
);

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = await promise;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      resolved.error = e.response.data.error;
    } else {
      resolved.error = "Se ha producido un error";
    }
  }

  return resolved;
}

// File a base64
const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// Funcion para subir noticias
export async function crearNoticia(data) {
  
  let auxArray = [];


  if (data.imagen) {
    for (var key in data.imagen) {
      console.log(data.imagen);
      data.imagen = await toBase64(data.imagen[0].file);
    }
  }
  const fd = new FormData();

  for (let index = 0; index < data.documentos_adjuntos.length; index++) {
    auxArray.push(data.documentos_adjuntos[index].file);
  }

  data.documentos_adjuntos = auxArray;

  for (var key in data) {
    if (data[key]) {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        if (data[key]) {
          for (let index = 0; index < data[key].length; index++) {
            if (typeof data[key][index].name == "string" && data.documentos_adjuntos.length>0) {
              fd.append(`documentos_adjuntos[${index}]`, data[key][index]);
            } else {
              fd.append(`oleicultores[${index}]`, JSON.stringify(data[key][index]));
            }
          }
        }
      } else {
        fd.append(key, data[key]);
      }
    }
  }

  return await resolve(
    instance_fd.post(`noticias/store`, fd).then((res) => res.data)
  );
}




// Funcion para editar noticias
export async function editarNoticiaSeleccionada(data,id) {
  
  let auxArray = [];

  if (data.imagen) {
    for (var key in data.imagen) {
      console.log(data.imagen);
      data.imagen = await toBase64(data.imagen[0].file);
    }
  }
  const fd = new FormData();

  for (let index = 0; index < data.documentos_adjuntos.length; index++) {
    auxArray.push(data.documentos_adjuntos[index].file);
  }

  data.documentos_adjuntos = auxArray;

  for (var key in data) {
    if (data[key]) {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        if (data[key]) {
          for (let index = 0; index < data[key].length; index++) {
            console.log(typeof data[key][index].name);
            if (typeof data[key][index].name == "string") {
              fd.append(`documentos_adjuntos[${index}]`, data[key][index]);
            } else {
              fd.append(`oleicultores[${index}]`, JSON.stringify(data[key][index]));
            }
          }
        }
      } else {
        fd.append(key, data[key]);
      }
    }
  }

  return await resolve(
    instance_fd.post(`noticias/update/${id}`, fd).then((res) => res.data)
  );
}

